var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","color":"grey lighten-5","flat":""}},[_c('v-row',{staticClass:"mx-sm-n3 mx-xs-n3",attrs:{"no-gutters":""}},[_c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-img',{attrs:{"src":require("@/assets/PROJECT_AGENDA.png"),"max-height":"50","max-width":"210","contain":""}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-img',{staticClass:"ml-n3 ml-sm-0 px-0 mr-0",attrs:{"src":require("@/assets/PROJECT_AGENDA_Isotipo.png"),"max-height":"50","max-width":"50","contain":""}}):_vm._e()],1),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[(!_vm.user.operatorRole)?_c('v-combobox',{ref:"numberComboBox",staticClass:"shrink",style:({
                    'margin-top': _vm.$vuetify.breakpoint.smAndDown
                        ? '4px'
                        : '',
                    'max-width': _vm.$vuetify.breakpoint.smAndDown
                        ? '170px'
                        : '300px',
                }),attrs:{"items":_vm.getQuotesByCustomizedParameters,"rounded":"","outlined":"","hide-details":"","dense":"","prepend-inner-icon":"mdi-magnify","append-icon":"","search-input":_vm.search,"item-text":"name","filter":_vm.filter,"loading":_vm.loading,"placeholder":"Search"},on:{"change":_vm.onAutoCompleteSelection,"keyup":_vm.customOnChangeHandler,"paste":_vm.customOnChangeHandler,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(
                            _vm.comboBoxModel && !_vm.$vuetify.breakpoint.smAndDown
                        )?_c('v-btn',{staticClass:"text-transform-none",attrs:{"text":"","elevation":"0","color":"primary","small":""},on:{"click":function($event){return _vm.searchQuote(true)}}},[_vm._v(" Search ")]):_vm._e()]},proxy:true}],null,false,990312593),model:{value:(_vm.comboBoxDummyModel),callback:function ($$v) {_vm.comboBoxDummyModel=$$v},expression:"comboBoxDummyModel"}}):_vm._e(),_c('NotificationsBell',{staticClass:"px-md-4 px-0"}),_c('v-btn',{staticClass:"ma-0 pa-0 px-md-4 px-0",style:({
                    'margin-top': _vm.$vuetify.breakpoint.smAndDown && '6px',
                }),attrs:{"disabled":_vm.user.operatorRole,"icon":""},on:{"click":_vm.openDialog}},[_c('v-avatar',{attrs:{"size":"40"}},[(_vm.profilePicURL)?_c('v-img',{attrs:{"src":_vm.profilePicURL,"alt":_vm.user.name}}):_c('v-icon',{attrs:{"x-large":"","color":"#707070"}},[_vm._v("mdi-account-circle")])],1)],1),(_vm.user.operatorRole)?_c('div',{staticClass:"ml-3"},[_c('v-btn',{staticClass:"ms-0 pa-0",attrs:{"icon":""},on:{"click":_vm.signOut}},[_c('v-icon',{attrs:{"large":"","color":"#707070"}},[_vm._v("mdi-logout")])],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.userDialog),callback:function ($$v) {_vm.userDialog=$$v},expression:"userDialog"}},[_c('UserForm',{attrs:{"title":"User Information","cardProfile":true,"create-form":false,"user":this.userToUpdate,"image":this.profilePicURL,"originalUser":this.originalUser},on:{"closeDialog":_vm.closeDialog,"replaceUser":this.replaceUser}})],1),_c('v-dialog',{attrs:{"retain-focus":false,"fullscreen":""},model:{value:(_vm.openQuoteDialog),callback:function ($$v) {_vm.openQuoteDialog=$$v},expression:"openQuoteDialog"}},[(_vm.openQuoteDialog)?_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"ml-n4"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openQuoteDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" Quote ")],1),_c('v-card-text',{staticClass:"px-0",style:(`height:${this.heightPopUp}px`)},[_c('Quote',{attrs:{"id":_vm.comboBoxModel.id,"quote":_vm.comboBoxModel,"settings":_vm.settings,"history":!!_vm.comboBoxModel.archive}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }